/* eslint-disable max-len,react/no-danger */
import React from "react";
import { graphql, Link } from "gatsby";
import get from "lodash/get";
import { PageHeader, Seo, Footer } from "components/common";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "components/commonStyles";
import { siteUrl } from "configs/constants";
import Img from "gatsby-image";

function ApiKey({ classes, data }) {
  const bgImage = get(data, "image.edges[0].node.childImageSharp.fluid", {});

  return (
    <>
      <Seo title="API Keys" url={`${siteUrl}/api-key`} />
      <PageHeader />
      <div className={classes.content}>
        <p style={{ marginBottom: 20 }}>
          How to get access to the secured data?
        </p>
        <p style={{ marginBottom: 20 }}>
          Before reading this article please read{" "}
          <span className={classes.textColor}>
            <Link to="/developers/data-domain/">Data domain</Link>
          </span>{" "}
          first.
        </p>
        <p style={{ marginBottom: 20 }}>
          Besides every cash register must be authenticated by it’s{" "}
          <Link to="/developers/api-key/">
            <span className={classes.textColor}>API key</span>
          </Link>
          , the srv4pos must know CashRegisterName and ProductionNumber of every
          cash register to confirm the legalization of your actions. Also, if
          agreed, srv4pos support team might represent your company for
          officials.
        </p>
        <p style={{ marginBottom: 20 }}>
          Therefore the procedure of getting API key supposes you send a little
          information about your cash register to srv4pos.
        </p>
        <p style={{ marginBottom: 60 }}>
          The following picture illustrates it.
        </p>
        <Img fluid={bgImage} style={{ marginBottom: 60 }} />
        <p style={{ marginBottom: 10 }}>
          You exectute POST /activations-advanced (or{" "}
          ActivationSerivice#create(…) ) and pass
        </p>
        <ul style={{ marginBottom: 30 }}>
          <li>CashRegisterName – it has to be created by user</li>
          <li>DeviceId</li>
          <li>
            Period (validFrom-validTo) for API key validation, maximum one year,{" "}
            <span className={classes.textHead}>
              it must be straight at midnight in UTC
            </span>
          </li>
          <li>CorporateId of the company that will use the cash register</li>
          <li>Identifier of a software that is called ApplicationPackage</li>
          <li>And some other auxiliary fields</li>
        </ul>
        <p style={{ marginBottom: 10 }}>And as a response you get</p>
        <ul style={{ marginBottom: 60 }}>
          <li>Automatically generated ProductionNumber</li>
          <li>APIkey!</li>
        </ul>
        <p style={{ marginBottom: 60 }}>
          So now you can pass it to Authorization header or to
          ActivationCredentials so that srv4pos can authenticate and authorize
          you!
        </p>
        <h4>I got 403, what’s the problem?</h4>
        <p style={{ marginBottom: 20 }}>
          Sometimes server cant authorize you because because Tax Authority must
          be notified about the registration of the cash register, but it
          wasn’t!
        </p>
        <p style={{ marginBottom: 20 }}>
          This is a manual process. Admin will notify Tax Authority, then he
          approves your request.
        </p>
        <p style={{ marginBottom: 20 }}>
          To check the latest status, if it’s approved or not, you may use
          /auth/handshake or handshake (…) (or any other) method.
        </p>
        <p style={{ marginBottom: 60 }}>
          Another problem of 403 might be that your API key is out of date.
          Please study the error (java) carefully. In this case you need to
          rerequest a new key. In this case there is no reason to send so many
          data as before. Here (java) is an example of how you might pass less
          amount of data.
        </p>
        <h4>Protection against malware usage at customer site</h4>
        <p>
          If your POS software is signed by the certificate which is signed by
          trusted CA (e.g. app from Google Play) but database may be accessed by
          3rd party and modified, you may use a special data structure srv4pos
          provides for you. It is called{" "}
          <span className={classes.textHead}>ActivationDescriptor</span>. It
          contains all the fields regarding your activation and you can put some
          additional information. srv4pos also provides you a digital signature
          of ActivationDescriptor. Assuming you have a public key inside your
          codebase, which is signed by digital certificate, 3rd party can’t
          modify it without breaking application signature. ActivationDescriptor
          and it’s signature can be placed to the database. ActivationDescriptor
          and it’s signature is returned by the server via{" "}
          /activations/activation-code or getActivationCode(…). Assuming
          Activation expires, and setting an expiration date is up to you, this
          feature gives you a great opportunity to protect licensing information
          for you POS software.
        </p>
      </div>
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    image: allFile(filter: { relativePath: { eq: "other/api-key.png" } }) {
      edges {
        node {
          childImageSharp {
            fluid(srcSetBreakpoints: [200, 340, 520, 800, 890], quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

ApiKey.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(ApiKey);
